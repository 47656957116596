@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

$bottomContainerHeight: 80%;
$middleContainerPadding: 5rem;

.wrapper {
    background-image: url('./../../assets/img/login-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    @include containerWrapper;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70vh;

    @include respond-below(lg) {
        flex-direction: column;
        height: auto;
        width: 80%;
    }
}

.recoverPasswordLink {
    cursor: pointer;

    &.disabled {
        color: rgba(color('gray'), 0.3);
        cursor: pointer;
    }
}

.left {
    background-color: color('primary');
    color: color('white');
    flex: 1;
    height: $bottomContainerHeight;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6rem;
    padding-right: 3rem;

    @include respond-below(lg) {
        width: 100%;
        height: auto;
        padding: 4rem;
        padding-right: 3rem;
    }

    h1 {
        font-size: 36px;
        @include fontWeight('bold');
        margin: 0;

        @include respond-below(lg) {
            font-size: 28px;
            margin-bottom: 2rem;
        }
    }

    h2 {
        font-size: 36px;
        @include fontWeight('light');
        margin: 0;

        @include respond-below(lg) {
            font-size: 28px;
            margin-bottom: 2rem;
        }
    }

    a {
        font-size: 20px;
        @include fontWeight('medium');
        text-decoration: none;
        color: color('white');
    }
}

.middle {
    background-color: color('white');
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: $middleContainerPadding;
    justify-content: center;
    position: relative;

    @include respond-below(lg) {
        width: 100%;
    }

    h1 {
        color: color('tundora');
        margin: 0;
        padding: 0;
        @include fontWeight('bold');
    }

    h2 {
        color: color('tundora');
        font-size: 24px;
        margin: 0;
        padding: 0;
        @include fontWeight('regular');
    }

    .firstItem {
        margin-top: 30px;
    }

    .goBackButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        left: $middleContainerPadding;
        top: $middleContainerPadding;
        cursor: pointer;
        

        @include respond-below(lg) {
            position: relative;
            top: unset;
            left: unset;
            margin-bottom: 10px;
        }
        
        .goBackButtonIcon {
            background-color: color('primary');
            color: color('white');
            border-radius: 50%;
            width: 30px;
            height: 30px;
            position: relative;

            svg {
                position: absolute;
                top: 8px;
                left: 7px;
            }
        }

        span {
            color: color('primary');
            margin-left: 8px;
        }
    }
    
    .goBackButtonIcon {
        background-color: color('primary');
        padding: 10px;
        color: color('white');
    }
}

.right {
    background-color: color('primary');
    width: 100px;
    height: $bottomContainerHeight;

    @include respond-below(lg) {
        display: none;
    }
}

.recoverTitle{
   
}

 .selectLogin {   
    div {
        background-color: color('catskillWhite');
        font-weight: 300;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        color: color('lightblack');     
    }
}